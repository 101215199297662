import flow from "lodash/flow";
import reduce from "lodash/fp/reduce";

export const countries = [
  { value: "US", label: "United States" },
  { value: "CA", label: "Canada" },
  { value: "AF", label: "Afghanistan" },
  { value: "AX", label: "Åland Islands" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CV", label: "Cape Verde" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo" },
  { value: "CD", label: "Congo, The Democratic Republic Of The" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Côte D'Ivoire" },
  { value: "HR", label: "Croatia" },
  { value: "CW", label: "Curaçao" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands (Malvinas)" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard and McDonald Islands" },
  { value: "VA", label: "Holy See (Vatican City State)" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KR", label: "Korea, Republic of" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Lao People's Democratic Republic" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MK", label: "Macedonia, the Former Yugoslav Republic Of" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia, Federated States Of" },
  { value: "MD", label: "Moldova, Republic of" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestine, State of" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "Réunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russian Federation" },
  { value: "RW", label: "Rwanda" },
  { value: "BL", label: "Saint Barthélemy" },
  { value: "SH", label: "Saint Helena" },
  { value: "KN", label: "Saint Kitts And Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "MF", label: "Saint Martin" },
  { value: "PM", label: "Saint Pierre And Miquelon" },
  { value: "VC", label: "Saint Vincent And The Grenedines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome and Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SX", label: "Sint Maarten" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia and the South Sandwich Islands" },
  { value: "SS", label: "South Sudan" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard And Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syrian Arab Republic" },
  { value: "TW", label: "Taiwan, Republic Of China" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania, United Republic of" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Türkiye, Republic of" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks and Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "UM", label: "United States Minor Outlying Islands" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela, Bolivarian Republic of" },
  { value: "VN", label: "Vietnam" },
  { value: "VG", label: "Virgin Islands, British" },
  { value: "VI", label: "Virgin Islands, U.S." },
  { value: "WF", label: "Wallis and Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
];

export const countryNameDictionary = countries.reduce((acc, country) =>
  Object.assign({}, acc, { [country.value]: country.label })
);

export const us_states = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AS", label: "American Samoa" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District of Columbia" },
  { value: "FM", label: "Federated States of Micronesia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "GU", label: "Guam" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Pennsylvania" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VI", label: "Virgin Islands" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
  { value: "AE", label: "Armed Forces Africa" },
  { value: "AA", label: "Armed Forces Americas" },
  { value: "AE", label: "Armed Forces Canada" },
  { value: "AE", label: "Armed Forces Europe" },
  { value: "AE", label: "Armed Forces Middle East" },
  { value: "AP", label: "Armed Forces Pacific" },
];
export const ca_provinces = [
  { value: "AB", label: "Alberta" },
  { value: "BC", label: "British Columbia" },
  { value: "MB", label: "Manitoba" },
  { value: "NB", label: "New Brunswick" },
  { value: "NL", label: "Newfoundland and Labrador" },
  { value: "NT", label: "Northwest Territories" },
  { value: "NS", label: "Nova Scotia" },
  { value: "NU", label: "Nunavut" },
  { value: "ON", label: "Ontario" },
  { value: "PE", label: "Prince Edward Island" },
  { value: "QC", label: "Quebec" },
  { value: "SK", label: "Saskatchewan" },
  { value: "YK", label: "Yukon" },
];
export const it_provinces = [
  { value: "AG", label: "Agrigento" },
  { value: "AL", label: "Alessandria" },
  { value: "AN", label: "Ancona" },
  { value: "AO", label: "Aosta" },
  { value: "AR", label: "Arezzo" },
  { value: "AP", label: "Ascoli Piceno" },
  { value: "AT", label: "Asti" },
  { value: "AV", label: "Avellino" },
  { value: "BA", label: "Bari" },
  { value: "BT", label: "Barletta-Andria-Trani" },
  { value: "BL", label: "Belluno" },
  { value: "BN", label: "Benevento" },
  { value: "BG", label: "Bergamo" },
  { value: "BI", label: "Biella" },
  { value: "BO", label: "Bologna" },
  { value: "BZ", label: "Bolzano" },
  { value: "BS", label: "Brescia" },
  { value: "BR", label: "Brindisi" },
  { value: "CA", label: "Cagliari" },
  { value: "CL", label: "Caltanissetta" },
  { value: "CB", label: "Campobasso" },
  { value: "CI", label: "Carbonia-Iglesias" },
  { value: "CE", label: "Caserta" },
  { value: "CT", label: "Catania" },
  { value: "CZ", label: "Catanzaro" },
  { value: "CH", label: "Chieti" },
  { value: "CO", label: "Como" },
  { value: "CS", label: "Cosenza" },
  { value: "CR", label: "Cremona" },
  { value: "KR", label: "Crotone" },
  { value: "CN", label: "Cuneo" },
  { value: "EN", label: "Enna" },
  { value: "FM", label: "Fermo" },
  { value: "FE", label: "Ferrara" },
  { value: "FI", label: "Florence" },
  { value: "FG", label: "Foggia" },
  { value: "FC", label: "Forlì-Cesena" },
  { value: "FR", label: "Frosinone" },
  { value: "GE", label: "Genoa" },
  { value: "GO", label: "Gorizia" },
  { value: "GR", label: "Grosseto" },
  { value: "IM", label: "Imperia" },
  { value: "IS", label: "Isernia" },
  { value: "SP", label: "La Spezia" },
  { value: "AQ", label: "L'Aquila" },
  { value: "LT", label: "Latina" },
  { value: "LE", label: "Lecce" },
  { value: "LC", label: "Lecco" },
  { value: "LI", label: "Livorno" },
  { value: "LO", label: "Lodi" },
  { value: "LU", label: "Lucca" },
  { value: "MC", label: "Macerata" },
  { value: "MN", label: "Mantua" },
  { value: "MS", label: "Massa and Carrara" },
  { value: "MT", label: "Matera" },
  { value: "VS", label: "Medio Campidano" },
  { value: "ME", label: "Messina" },
  { value: "MI", label: "Milan" },
  { value: "MO", label: "Modena" },
  { value: "MB", label: "Monza and Brianza" },
  { value: "NA", label: "Naples" },
  { value: "NO", label: "Novara" },
  { value: "NU", label: "Nuoro" },
  { value: "OG", label: "Ogliastra" },
  { value: "OT", label: "Olbia-Tempio" },
  { value: "OR", label: "Oristano" },
  { value: "PD", label: "Padua" },
  { value: "PA", label: "Palermo" },
  { value: "PR", label: "Parma" },
  { value: "PV", label: "Pavia" },
  { value: "PG", label: "Perugia" },
  { value: "PU", label: "Pesaro and Urbino" },
  { value: "PE", label: "Pescara" },
  { value: "PC", label: "Piacenza" },
  { value: "PI", label: "Pisa" },
  { value: "PT", label: "Pistoia" },
  { value: "PN", label: "Pordenone" },
  { value: "PZ", label: "Potenza" },
  { value: "PO", label: "Prato" },
  { value: "RG", label: "Ragusa" },
  { value: "RA", label: "Ravenna" },
  { value: "RC", label: "Reggio Calabria" },
  { value: "RE", label: "Reggio Emilia" },
  { value: "RI", label: "Rieti" },
  { value: "RN", label: "Rimini" },
  { value: "RM", label: "Rome" },
  { value: "RO", label: "Rovigo" },
  { value: "SA", label: "Salerno" },
  { value: "SS", label: "Sassari" },
  { value: "SV", label: "Savona" },
  { value: "SI", label: "Siena" },
  { value: "SO", label: "Sondrio" },
  { value: "SR", label: "Syracuse" },
  { value: "TA", label: "Taranto" },
  { value: "TE", label: "Teramo" },
  { value: "TR", label: "Terni" },
  { value: "TP", label: "Trapani" },
  { value: "TN", label: "Trento" },
  { value: "TV", label: "Treviso" },
  { value: "TS", label: "Trieste" },
  { value: "TO", label: "Turin" },
  { value: "UD", label: "Udine" },
  { value: "VA", label: "Varese" },
  { value: "VE", label: "Venice" },
  { value: "VB", label: "Verbano-Cusio-Ossola" },
  { value: "VC", label: "Vercelli" },
  { value: "VR", label: "Verona" },
  { value: "VV", label: "Vibo Valentia" },
  { value: "VI", label: "Vicenza" },
  { value: "VT", label: "Viterbo" },
];
export const au_states = [
  { value: "ACT", label: "Australian Capital Territories" },
  { value: "NSW", label: "New South Wales" },
  { value: "NT", label: "Northern Territories" },
  { value: "QLD", label: "Queensland" },
  { value: "SA", label: "South Australia" },
  { value: "TAS", label: "Tasmania" },
  { value: "VIC", label: "Victoria" },
  { value: "WA", label: "Western Australia" },
];
export const ca_legal_types = [
  { value: "CCT", label: "Canadian Citizen" },
  { value: "CCO", label: "Canadian Corporation" },
  { value: "ABO", label: "Aboriginal" },
  { value: "ASS", label: "Association" },
  { value: "EDU", label: "Educational Institution" },
  { value: "GOV", label: "Government" },
  { value: "HOP", label: "Hospital" },
  { value: "INB", label: "Indian Band" },
  { value: "LAM", label: "Library, Archive, or Museum" },
  { value: "LGR", label: "Legal Representative" },
  { value: "MAJ", label: "His Majesty the King" },
  { value: "OMK", label: "Protected by Trademarks Act" },
  { value: "PLT", label: "Political Party" },
  { value: "PRT", label: "Partnership" },
  { value: "RES", label: "Permanent Resident" },
  { value: "TDM", label: "Trademark Owner" },
  { value: "TRD", label: "Trade Union" },
  { value: "TRS", label: "Trust" },
];
export const us_purposes = [
  { value: "P1", label: "Business use for profit" },
  {
    value: "P2",
    label:
      "Non-profit business, club, association, religious organization, and so on",
  },
  { value: "P3", label: "Personal use" },
  { value: "P4", label: "Education purposes" },
  { value: "P5", label: "Government purposes" },
];
export const us_categories = [
  {
    value: "C11",
    label: "A citizen of the U.S. or any of its territories",
  },
  {
    value: "C12",
    label: "A permanent resident of the U.S. or any of its territories",
  },
  { value: "C21", label: "An organization incorporated within the U.S." },
  {
    value: "C31",
    label: "An entity that regularly engages in lawful activities in the U.S.",
  },
  {
    value: "C32",
    label: "An entity that has an office or other facility in the U.S.",
  },
];
export const uk_reg_types = [
  { value: "IND", label: "UK Individual (representing self)" },
  { value: "FIND", label: "Non-UK Individual (representing self)" },
  {
    value: "OTHER",
    label: "UK Entity that does not fit into any of the above",
  },
];

export const eppCodes = {
  AD: "376",
  AE: "971",
  AF: "93",
  AG: "1",
  AI: "1",
  AL: "255",
  AM: "374",
  AN: "599",
  AO: "244",
  AQ: "672",
  AR: "54",
  AS: "684",
  AT: "43",
  AU: "61",
  AW: "297",
  AZ: "994",
  BA: "387",
  BB: "1",
  BD: "880",
  BE: "32",
  BF: "226",
  BG: "359",
  BH: "973",
  BI: "257",
  BJ: "229",
  BM: "1",
  BN: "673",
  BO: "591",
  BR: "55",
  BS: "1",
  BT: "975",
  BW: "267",
  BY: "375",
  BZ: "501",
  CA: "1",
  CC: "61",
  CD: "243",
  CF: "236",
  CG: "242",
  CH: "41",
  CI: "225",
  CK: "682",
  CL: "56",
  CM: "237",
  CN: "86",
  CO: "57",
  CR: "506",
  CS: "420",
  CV: "238",
  CX: "61",
  CY: "357",
  CZ: "420",
  DE: "49",
  DJ: "253",
  DK: "45",
  DM: "1",
  DO: "1",
  DZ: "313",
  EC: "593",
  EE: "372",
  EG: "20",
  ER: "291",
  ES: "34",
  ET: "251",
  FI: "358",
  FJ: "679",
  FK: "500",
  FM: "691",
  FO: "298",
  FR: "33",
  GA: "241",
  GB: "44",
  GD: "1",
  GE: "995",
  GF: "594",
  GH: "233",
  GI: "350",
  GL: "299",
  GM: "220",
  GN: "224",
  GP: "590",
  GQ: "240",
  GR: "30",
  GT: "502",
  GU: "1",
  GW: "225",
  GY: "592",
  HK: "852",
  HN: "504",
  HR: "385",
  HT: "509",
  HU: "36",
  ID: "62",
  IE: "353",
  IL: "972",
  IN: "91",
  IQ: "964",
  IS: "354",
  IT: "39",
  JE: "81",
  JM: "1",
  JO: "962",
  JP: "876",
  KE: "254",
  KG: "996",
  KH: "855",
  KI: "686",
  KM: "269",
  KN: "1",
  KR: "82",
  KW: "965",
  KY: "1",
  KZ: "7",
  LA: "856",
  LB: "961",
  LC: "1",
  LI: "423",
  LK: "94",
  LR: "231",
  LS: "266",
  LT: "370",
  LU: "352",
  LV: "371",
  LY: "218",
  MA: "212",
  MC: "377",
  MD: "373",
  MG: "261",
  MH: "692",
  MK: "389",
  ML: "223",
  MM: "95",
  MN: "976",
  MO: "853",
  MP: "1",
  MQ: "596",
  MR: "222",
  MS: "1",
  MT: "356",
  MU: "230",
  MV: "960",
  MW: "265",
  MX: "52",
  MY: "60",
  MZ: "258",
  NA: "264",
  NC: "687",
  NE: "227",
  NF: "672",
  NG: "234",
  NI: "505",
  NL: "31",
  NO: "47",
  NP: "977",
  NR: "674",
  NU: "683",
  NZ: "64",
  OM: "968",
  PA: "507",
  PE: "51",
  PF: "689",
  PG: "675",
  PH: "63",
  PK: "92",
  PL: "48",
  PM: "508",
  PR: "1",
  PT: "351",
  PW: "680",
  PY: "595",
  QA: "974",
  RE: "262",
  RO: "40",
  RU: "7",
  RW: "250",
  SA: "966",
  SB: "677",
  SC: "248",
  SD: "249",
  SE: "46",
  SG: "65",
  SH: "290",
  SI: "386",
  SK: "421",
  SL: "232",
  SM: "378",
  SN: "221",
  SO: "252",
  SR: "597",
  ST: "239",
  SV: "503",
  SY: "963",
  SZ: "267",
  TC: "1",
  TD: "235",
  TG: "228",
  TH: "66",
  TJ: "992",
  TK: "690",
  TM: "993",
  TN: "216",
  TO: "1",
  TP: "670",
  TR: "90",
  TV: "688",
  TW: "886",
  TZ: "255",
  UA: "380",
  UG: "256",
  UK: "44",
  UM: "1",
  US: "1",
  UY: "598",
  UZ: "998",
  VA: "39",
  VC: "1",
  VE: "58",
  VG: "1",
  VI: "1",
  VN: "84",
  VU: "678",
  WF: "681",
  WS: "685",
  YE: "967",
  YT: "269",
  YU: "381",
  ZA: "27",
  ZM: "260",
  ZR: "243",
};

const countryCodes = [
  { value: "US+1", label: "United States (+1)" },
  { value: "CA+1", label: "Canada (+1)" },
  { value: "AF+93", label: "Afghanistan (+93)" },
  { value: "AL+355", label: "Albania (+355)" },
  { value: "DZ+213", label: "Algeria (+213)" },
  { value: "AS+684", label: "American Samoa (+684)" },
  { value: "AO+376", label: "Andorra (+376)" },
  { value: "AO+244", label: "Angola (+244)" },
  { value: "AI+1", label: "Anguilla (+1)" },
  { value: "AQ+672", label: "Antarctica (+672)" },
  { value: "AG+1", label: "Antigua and Barbuda (+1)" },
  { value: "AR+54", label: "Argentina (+54)" },
  { value: "AM+374", label: "Armenia (+374)" },
  { value: "AW+297", label: "Aruba (+297)" },
  { value: "AU+61", label: "Australia (+61)" },
  { value: "AT+43", label: "Austria (+43)" },
  { value: "AZ+994", label: "Azerbaijan (+994)" },
  { value: "BS+1", label: "Bahamas (+1)" },
  { value: "BH+973", label: "Bahrain (+973)" },
  { value: "BD+880", label: "Bangladesh (+880)" },
  { value: "BB+1", label: "Barbados (+1)" },
  { value: "BY+375", label: "Belarus (+375)" },
  { value: "BE+32", label: "Belgium (+32)" },
  { value: "BZ+501", label: "Belize (+501)" },
  { value: "BJ+229", label: "Benin (+229)" },
  { value: "BM+1", label: "Bermuda (+1)" },
  { value: "BT+975", label: "Bhutan (+975)" },
  { value: "BO+591", label: "Bolivia (+591)" },
  { value: "BA+387", label: "Bosnia and Herzegovina (+387)" },
  { value: "BW+267", label: "Botswana (+267)" },
  { value: "BR+55", label: "Brazil (+55)" },
  { value: "BN+673", label: "Brunei Darussalam (+673)" },
  { value: "BG+359", label: "Bulgaria (+359)" },
  { value: "BF+226", label: "Burkina Faso (+226)" },
  { value: "BI+257", label: "Burundi (+257)" },
  { value: "KH+855", label: "Cambodia (+855)" },
  { value: "CM+237", label: "Cameroon (+237)" },
  { value: "CV+238", label: "Cape Verde (+238)" },
  { value: "KY+1", label: "Cayman Islands (+1)" },
  { value: "CF+236", label: "Central African Republic (+236)" },
  { value: "TD+235", label: "Chad (+235)" },
  { value: "CL+56", label: "Chile (+56)" },
  { value: "CN+86", label: "China (+86)" },
  { value: "CX+61", label: "Christmas Island (+61)" },
  { value: "CC+61", label: "Cocos (Keeling) Islands (+61)" },
  { value: "CO+57", label: "Colombia (+57)" },
  { value: "KM+269", label: "Comoros (+269)" },
  { value: "CG+242", label: "Congo (+242)" },
  {
    value: "CD+243",
    label: "Congo, The Democratic Republic Of The (+243)",
  },
  { value: "CK+682", label: "Cook Islands (+682)" },
  { value: "CR+506", label: "Costa Rica (+506)" },
  { value: "CI+225", label: "Côte D'Ivoire (+225)" },
  { value: "HR+385", label: "Croatia (+385)" },
  { value: "CU+53", label: "Cuba (+53)" },
  { value: "CY+357", label: "Cyprus (+357)" },
  { value: "CZ+420", label: "Czech Republic (+420)" },
  { value: "DK+45", label: "Denmark (+45)" },
  { value: "DJ+253", label: "Djibouti (+253)" },
  { value: "DM+1", label: "Dominica (+1)" },
  { value: "DO+1", label: "Dominican Republic (+1)" },
  { value: "EC+593", label: "Ecuador (+593)" },
  { value: "EG+20", label: "Egypt (+20)" },
  { value: "SV+503", label: "El Salvador (+503)" },
  { value: "GQ+240", label: "Equatorial Guinea (+240)" },
  { value: "ER+291", label: "Eritrea (+291)" },
  { value: "EE+372", label: "Estonia (+372)" },
  { value: "ET+251", label: "Ethiopia (+251)" },
  { value: "FK+500", label: "Falkland Islands (Malvinas) (+500)" },
  { value: "FO+298", label: "Faroe Islands (+298)" },
  { value: "FJ+679", label: "Fiji (+679)" },
  { value: "FI+358", label: "Finland (+358)" },
  { value: "FR+33", label: "France (+33)" },
  { value: "GF+594", label: "French Guiana (+594)" },
  { value: "PF+689", label: "French Polynesia (+689)" },
  { value: "GA+241", label: "Gabon (+241)" },
  { value: "GM+220", label: "Gambia (+220)" },
  { value: "GE+995", label: "Georgia (+995)" },
  { value: "DE+49", label: "Germany (+49)" },
  { value: "GH+233", label: "Ghana (+233)" },
  { value: "GI+350", label: "Gibraltar (+350)" },
  { value: "GR+30", label: "Greece (+30)" },
  { value: "GL+299", label: "Greenland (+299)" },
  { value: "GD+1", label: "Grenada (+1)" },
  { value: "GP+590", label: "Guadeloupe (+590)" },
  { value: "GU+1", label: "Guam (+1)" },
  { value: "GT+502", label: "Guatemala (+502)" },
  { value: "GN+224", label: "Guinea (+224)" },
  { value: "GW+245", label: "Guinea-Bissau (+245)" },
  { value: "GY+592", label: "Guyana (+592)" },
  { value: "HT+509", label: "Haiti (+509)" },
  { value: "VA+39", label: "Holy See (Vatican City State) (+39)" },
  { value: "HN+504", label: "Honduras (+504)" },
  { value: "HK+852", label: "Hong Kong (+852)" },
  { value: "HU+36", label: "Hungary (+36)" },
  { value: "IS+354", label: "Iceland (+354)" },
  { value: "IN+91", label: "India (+91)" },
  { value: "ID+62", label: "Indonesia (+62)" },
  { value: "IR+98", label: "Iran, Islamic Republic Of (+98)" },
  { value: "IQ+964", label: "Iraq (+964)" },
  { value: "IE+353", label: "Ireland (+353)" },
  { value: "IL+972", label: "Israel (+972)" },
  { value: "IT+39", label: "Italy (+39)" },
  { value: "JM+1", label: "Jamaica (+1)" },
  { value: "JP+81", label: "Japan (+81)" },
  { value: "JE+44", label: "Jersey (+44)" },
  { value: "JO+962", label: "Jordan (+962)" },
  { value: "KZ+7", label: "Kazakhstan (+7)" },
  { value: "KE+254", label: "Kenya (+254)" },
  { value: "KI+686", label: "Kiribati (+686)" },
  {
    value: "KP+850",
    label: "Korea, Democratic People's Republic Of (+850)",
  },
  { value: "KR+82", label: "Korea, Republic of (+82)" },
  { value: "KW+965", label: "Kuwait (+965)" },
  { value: "KG+996", label: "Kyrgyzstan (+996)" },
  { value: "LA+856", label: "Lao People's Democratic Republic (+856)" },
  { value: "LV+371", label: "Latvia (+371)" },
  { value: "LB+961", label: "Lebanon (+961)" },
  { value: "LS+266", label: "Lesotho (+266)" },
  { value: "LR+231", label: "Liberia (+231)" },
  { value: "LY+218", label: "Libya (+218)" },
  { value: "LI+423", label: "Liechtenstein (+423)" },
  { value: "LT+370", label: "Lithuania (+370)" },
  { value: "LU+352", label: "Luxembourg (+352)" },
  { value: "MO+853", label: "Macao (+853)" },
  {
    value: "MK+389",
    label: "Macedonia, the Former Yugoslav Republic Of (+389)",
  },
  { value: "MG+261", label: "Madagascar (+261)" },
  { value: "MW+265", label: "Malawi (+265)" },
  { value: "MY+60", label: "Malaysia (+60)" },
  { value: "MV+960", label: "Maldives (+960)" },
  { value: "ML+223", label: "Mali (+223)" },
  { value: "MT+356", label: "Malta (+356)" },
  { value: "MH+692", label: "Marshall Islands (+692)" },
  { value: "MQ+596", label: "Martinique (+596)" },
  { value: "MR+222", label: "Mauritania (+222)" },
  { value: "MU+230", label: "Mauritius (+230)" },
  { value: "YT+262", label: "Mayotte (+262)" },
  { value: "MX+52", label: "Mexico (+52)" },
  { value: "FM+691", label: "Micronesia, Federated States Of (+691)" },
  { value: "MD+373", label: "Moldova, Republic of (+373)" },
  { value: "MC+377", label: "Monaco (+377)" },
  { value: "MN+976", label: "Mongolia (+976)" },
  { value: "MS+1", label: "Montserrat (+1)" },
  { value: "MA+212", label: "Morocco (+212)" },
  { value: "MZ+258", label: "Mozambique (+258)" },
  { value: "MM+95", label: "Myanmar (+95)" },
  { value: "NA+264", label: "Namibia (+264)" },
  { value: "NR+674", label: "Nauru (+674)" },
  { value: "NP+977", label: "Nepal (+977)" },
  { value: "NL+31", label: "Netherlands (+31)" },
  { value: "NC+687", label: "New Caledonia (+687)" },
  { value: "NZ+64", label: "New Zealand (+64)" },
  { value: "NI+505", label: "Nicaragua (+505)" },
  { value: "NE+227", label: "Niger (+227)" },
  { value: "NG+234", label: "Nigeria (+234)" },
  { value: "NU+683", label: "Niue (+683)" },
  { value: "NF+672", label: "Norfolk Island (+672)" },
  { value: "MP+1", label: "Northern Mariana Islands (+1)" },
  { value: "NO+47", label: "Norway (+47)" },
  { value: "OM+968", label: "Oman (+968)" },
  { value: "PK+92", label: "Pakistan (+92)" },
  { value: "PW+680", label: "Palau (+680)" },
  { value: "PA+507", label: "Panama (+507)" },
  { value: "PG+675", label: "Papua New Guinea (+675)" },
  { value: "PY+595", label: "Paraguay (+595)" },
  { value: "PE+51", label: "Peru (+51)" },
  { value: "PH+63", label: "Philippines (+63)" },
  { value: "PL+48", label: "Poland (+48)" },
  { value: "PT+351", label: "Portugal (+351)" },
  { value: "PR+1", label: "Puerto Rico (+1)" },
  { value: "QA+974", label: "Qatar (+974)" },
  { value: "RE+262", label: "Réunion (+262)" },
  { value: "RO+40", label: "Romania (+40)" },
  { value: "RU+7", label: "Russian Federation (+7)" },
  { value: "RW+250", label: "Rwanda (+250)" },
  { value: "SH+290", label: "Saint Helena (+290)" },
  { value: "KN+1", label: "Saint Kitts And Nevis (+1)" },
  { value: "LC+1", label: "Saint Lucia (+1)" },
  { value: "PM+508", label: "Saint Pierre And Miquelon (+508)" },
  { value: "VC+1", label: "Saint Vincent And The Grenedines (+1)" },
  { value: "WS+685", label: "Samoa (+685)" },
  { value: "SM+378", label: "San Marino (+378)" },
  { value: "ST+239", label: "Sao Tome and Principe (+239)" },
  { value: "SA+966", label: "Saudi Arabia (+966)" },
  { value: "SN+221", label: "Senegal (+221)" },
  { value: "SC+248", label: "Seychelles (+248)" },
  { value: "SL+232", label: "Sierra Leone (+232)" },
  { value: "SG+65", label: "Singapore (+65)" },
  { value: "SK+421", label: "Slovakia (+421)" },
  { value: "SI+386", label: "Slovenia (+386)" },
  { value: "SB+677", label: "Solomon Islands (+677)" },
  { value: "SO+252", label: "Somalia (+252)" },
  { value: "ZA+27", label: "South Africa (+27)" },
  { value: "ES+34", label: "Spain (+34)" },
  { value: "LK+94", label: "Sri Lanka (+94)" },
  { value: "SD+249", label: "Sudan (+249)" },
  { value: "SR+597", label: "Suriname (+597)" },
  { value: "SZ+268", label: "Swaziland (+268)" },
  { value: "SE+46", label: "Sweden (+46)" },
  { value: "CH+41", label: "Switzerland (+41)" },
  { value: "SY+963", label: "Syrian Arab Republic (+963)" },
  { value: "TW+886", label: "Taiwan, Republic Of China (+886)" },
  { value: "TJ+992", label: "Tajikistan (+992)" },
  { value: "TZ+255", label: "Tanzania, United Republic of (+255)" },
  { value: "TH+66", label: "Thailand (+66)" },
  { value: "TG+228", label: "Togo (+228)" },
  { value: "TK+690", label: "Tokelau (+690)" },
  { value: "TO+1", label: "Tonga (+1)" },
  { value: "TU+216", label: "Tunisia (+216)" },
  { value: "TR+90", label: "Türkiye, Republic of (+90)" },
  { value: "TM+993", label: "Turkmenistan (+993)" },
  { value: "TC+1", label: "Turks and Caicos Islands (+1)" },
  { value: "TV+688", label: "Tuvalu (+688)" },
  { value: "UG+256", label: "Uganda (+256)" },
  { value: "UA+380", label: "Ukraine (+380)" },
  { value: "AE+971", label: "United Arab Emirates (+971)" },
  { value: "UK+44", label: "United Kingdom (+44)" },
  { value: "UM+1", label: "United States Minor Outlying Islands (+1)" },
  { value: "UY+598", label: "Uruguay (+598)" },
  { value: "UZ+998", label: "Uzbekistan (+998)" },
  { value: "VU+678", label: "Vanuatu (+678)" },
  { value: "VE+58", label: "Venezuela, Bolivarian Republic of (+58)" },
  { value: "VN+84", label: "Vietnam (+84)" },
  { value: "VG +1", label: "Virgin Islands, British (+1)" },
  { value: "VI+1", label: "Virgin Islands, U.S. (+1)" },
  { value: "WF+681", label: "Wallis and Futuna (+681)" },
  { value: "YE+967", label: "Yemen (+967)" },
  { value: "ZM+260", label: "Zambia (+260)" },
];

export function filterCountryCodes(bannedCountryCodes) {
  return countryCodes.filter((code) => !bannedCountryCodes.includes(code.value));
}
