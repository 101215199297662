import c from "classnames";
import { h, fasIcon } from "h";

function price(amount, regular) {
  if (!regular || amount == regular) {
    return addCents(amount);
  } else {
    return [
      h("span.promo_price", addCents(amount, "FREE")),
      " / ",
      h("span.regular_price", addCents(regular))
    ];
  }
}

function addCents(amount, ifZero) {
  if (amount && amount.indexOf(".") < 0) {
    return checkZero(amount + ".00");
  } else {
    return checkZero(amount);
  }

  function checkZero(a) {
    if (a === "$0.00") return ifZero;
    else return a;
  }
}

function priceSpans(props) {
  const { regular_price, custom_price, promo_price, voucher_price, premium_type, price } = props;
  if (premium_type === "tiered") {
    return h("span.regular_price", price);
  } else {
    const entries = Object.entries({ regular_price, custom_price, promo_price, voucher_price });

    return entries
      .filter(entry => entry[1])
      .sort(sortPrices)
      .map(price => (currToNum(price[1]) === 0 ? [price[0], "FREE "] : price))
      .map(toSpan)
      .reduce(insertSlashes, []);
  }
}

function toSpan(price, idx) {
  return h(
    "span",
    {
      className: price[0]
    },
    [
      priceIcon(price[0]),
      h("span", { className: c({ "strike-through": idx > 0 }) }, price[1])
    ]
  );
}

function insertSlashes(result, element, index, array) {
  result.push(element);
  if (index < array.length - 1) {
    result.push(h("span.slash", " / "));
  }
  return result;
}

function priceIcon(priceType) {
  switch (priceType) {
    case "promo_price":
      return h("span.icon", "SALE");

      case "custom_price":
        return h("span.icon", "SALE");

    case "voucher_price":
      return fasIcon("ticket");

    default:
      return null;
  }
}

function sortPrices(a, b) {
  if (currToNum(a[1]) < currToNum(b[1])) {
    return -1;
  } else if (currToNum(a[1]) > currToNum(b[1])) {
    return 1;
  } else if (promoAndVoucher(a, b)) {
    if(a[0] === "promo_price"){
      return -1
    } else {
      return 1;
    }
  }
  return 0;
}

function promoAndVoucher(a, b) {
  return (
    (a[0] === "promo_price" || a[0] === "voucher_price") &&
    (b[0] === "promo_price" || b[0] === "voucher_price")
  );
}

const currToNum = curr => Number(curr.replace(/[^0-9.-]+/g, ""));

export { price, priceSpans };
